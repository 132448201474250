/* You can add global styles to this file, and also import other style files */

@import 'react-quill/dist/quill.snow.css';
html {
  font-size: 16px;
  height: 100%;
}

body {
  font-family: 'Poppins', 'Lato', sans-serif;
  height: 100%;
}
html * {
  padding: 0;
  margin: 0;
}
#root {
  height: 100%;
}
/* html::-webkit-scrollbar {
  display: none;
} */

/* Text Editor styles */

.ql-toolbar .ql-stroke {
  fill: none !important;
  stroke: #fff !important;
}

.ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}

.ql-toolbar .ql-picker {
  color: #fff !important;
}
.ql-toolbar .ql-picker .ql-expanded {
  color: #0a0a0a !important;
}
.ql-toolbar .ql-picker .ql-active {
  color: #0a0a0a !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #050505 !important;
}
.ql-toolbar .ql-expanded .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}
.ql-toolbar .ql-expanded .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}
.ql-snow .ql-picker .ql-picker-options {
  color: #050505 !important;
}
.ql-snow .ql-align .ql-picker-options .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}

.ql-toolbar .ql-active .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}
.ql-toolbar .ql-active .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}
.ql-snow .ql-align .ql-picker-options .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}

.textEditor .quill {
  border: 1px solid #cce6fe;
  /* height: 100%; */
  max-height: 100% !important;
}
.ql-container.ql-snow {
  border-color: transparent !important;
}

#custom {
  /* height: 18px !important; */
  width: 18px !important;
  border: none !important ;
  box-sizing: border-box !important;
  /* background-color: red !important; */
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  font-weight: 700;
  content: 'T' !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border: none !important;
}

#custom svg {
  display: none;
}

/* .ql-format svg {
  height: 5px !important;
  width: 5px !important;
} */

.ql-undo:active .ql-stroke,
.ql-redo:active .ql-stroke {
  fill: none !important;
  stroke: #080808 !important;
}
.ql-undo:active .ql-fill,
.ql-redo:active .ql-fill {
  fill: #0e0d0d !important;
  stroke: none !important;
}

#toolbar .ql-picker-label {
  display: block !important;
  width: 18px !important;
  box-sizing: border-box !important;
  justify-content: center;
  align-items: center;
}
#toolbar .ql-picker-label svg {
  /* background-color: red !important; */
  height: 18px !important;
  width: 18px !important;
}
#toolbar button,
#toolbar > span {
  box-sizing: border-box !important;

  width: 7.5% !important;
  /* background-color: rgb(243, 81, 17) !important; */
}

.ql-editor::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin: 5px;
}

/* scrollbar track */
.ql-editor::-webkit-scrollbar-track {
  margin: 5px;
  background: #eee;
}

/* scrollbar handle */
.ql-editor::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #343636;
}
